/* eslint-disable no-restricted-globals */
const tslib_1 = require("tslib");
const tab_1 = require("gatekeeper-client-sdk/src/core/tab");
const js_base64_1 = require("js-base64");
const fingerprintjs_1 = require("@fingerprintjs/fingerprintjs");
class Authgatekeeper {
  constructor() {
    this.LOCALSTORAGE_IDENTIFIER = "gatekeeper_token";
    this.URL = "https://admin.rockmontwebsolutions.in/backend/api";
    this.payload = {
      accountID: null,
      clientURL: null,
      browserID: null,
    };
    this.tab = new tab_1.Tab();
    fingerprintjs_1.default
      .load()
      .then((fp) => fp.get())
      .then(({ visitorId }) => (this.payload.browserID = visitorId));
  }

  getBase64Payload(additional) {
    const data = Object.assign(Object.assign({}, this.payload), additional);
    return (0, js_base64_1.encode)(JSON.stringify(data));
  }

  static getInstance() {
    if (!Authgatekeeper.instance) {
      Authgatekeeper.instance = new Authgatekeeper();
    }
    return Authgatekeeper.instance;
  }

  initialize(accountID) {
    this.payload.accountID = accountID;
    this.payload.clientURL = `${location.protocol}//${location.host}`;
  }

  loginByAuth(email, password) {
    return (0, tslib_1.__awaiter)(this, void 0, void 0, function* () {
      try {
        const response = yield fetch(`${this.URL}/v1/auth/login`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            AccountID: this.payload.accountID,
          },
          body: JSON.stringify({ email, password }),
        });
        const data = yield response.json();
        if (data && data.status && data.message) {
          throw new Error(data.message);
        }
        localStorage.setItem(this.LOCALSTORAGE_IDENTIFIER, data.token);
        return data.token;
      } catch (error) {
        throw error;
      }
    });
  }

  registerByAuth(email, password) {
    return (0, tslib_1.__awaiter)(this, void 0, void 0, function* () {
      try {
        const response = yield fetch(`${this.URL}/v1/auth/register`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            AccountID: this.payload.accountID,
          },
          body: JSON.stringify({ email, password }),
        });
        const data = yield response.json();
        if (data && data.status && data.message) {
          throw new Error(data.message);
        }
        localStorage.setItem(this.LOCALSTORAGE_IDENTIFIER, data.token);
        return data.token;
      } catch (error) {
        console.log(error);
        throw error;
      }
    });
  }

  loginByGoogle() {
    return (0, tslib_1.__awaiter)(this, void 0, void 0, function* () {
      try {
        const { token } = yield this.tab.open(
          `${this.URL}/v1/auth/google?payload=${this.getBase64Payload({
            type: "LOGIN",
          })}`
        );
        localStorage.setItem(this.LOCALSTORAGE_IDENTIFIER, token);
        return token;
      } catch (error) {
        console.log(error);
        throw error;
      }
    });
  }

  registerByGoogle() {
    return (0, tslib_1.__awaiter)(this, void 0, void 0, function* () {
      try {
        const { token } = yield this.tab.open(
          `${this.URL}/v1/auth/google?payload=${this.getBase64Payload({
            type: "REGISTER",
          })}`
        );
        localStorage.setItem(this.LOCALSTORAGE_IDENTIFIER, token);
        return token;
      } catch (error) {
        console.log(error);
        throw error;
      }
    });
  }

  loginByFacebook() {
    return (0, tslib_1.__awaiter)(this, void 0, void 0, function* () {
      try {
        const { token } = yield this.tab.open(
          `${this.URL}/v1/auth/facebook?payload=${this.getBase64Payload({
            type: "LOGIN",
          })}`
        );
        localStorage.setItem(this.LOCALSTORAGE_IDENTIFIER, token);
        return token;
      } catch (error) {
        console.log(error);
        throw error;
      }
    });
  }

  registerByFacebook() {
    return (0, tslib_1.__awaiter)(this, void 0, void 0, function* () {
      try {
        const { token } = yield this.tab.open(
          `${this.URL}/v1/auth/facebook?payload=${this.getBase64Payload({
            type: "REGISTER",
          })}`
        );
        localStorage.setItem(this.LOCALSTORAGE_IDENTIFIER, token);
        return token;
      } catch (error) {
        console.log(error);
        throw error;
      }
    });
  }

  getProfile() {
    return (0, tslib_1.__awaiter)(this, void 0, void 0, function* () {
      const token = localStorage.getItem(this.LOCALSTORAGE_IDENTIFIER);
      const response = yield fetch(`${this.URL}/v1/users/profile`, {
        headers: {
          "Content-Type": "application/json",
          AccountID: this.payload.accountID,
          Authorization: `Bearer ${token}`,
        },
      });
      const data = yield response.json();
      if (data && data.status && data.message) {
        throw new Error(data.message);
      }
      return data.data;
    });
  }
  // users detail
  loadUserDetails(id) {
    return (0, tslib_1.__awaiter)(this, void 0, void 0, function* () {
      try {
        const token = localStorage.getItem(this.LOCALSTORAGE_IDENTIFIER);
        const response = yield fetch(`${this.URL}/v1/users/detail/${id}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            AccountID: this.payload.accountID,
            Authorization: `Bearer ${token}`,
          },
        });
        const result = yield response.json();
        if (result && result.error && result.message) {
          throw new Error(result.message);
        }
        return result.data;
      } catch (error) {
        throw error;
      }
    });
  }
  // list of users
  loadUsers() {
    return (0, tslib_1.__awaiter)(this, void 0, void 0, function* () {
      try {
        const token = localStorage.getItem(this.LOCALSTORAGE_IDENTIFIER);
        const response = yield fetch(`${this.URL}/v1/users`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            AccountID: this.payload.accountID,
            Authorization: `Bearer ${token}`,
          },
        });
        const result = yield response.json();
        if (result && result.error && result.message) {
          throw new Error(result.message);
        }
        return result.data;
      } catch (error) {
        throw error;
      }
    });
  }
}
export default Authgatekeeper.getInstance();
