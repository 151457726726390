/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { ContentHeader } from "@components";
//import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
//import { useNavigate } from "react-router-dom";
import { sleep } from "@app/utils/helpers";
import { loadUsers } from "@app/store/reducers/auth";
//import { useParams } from "react-router-dom";
import authgatekeeper from "@app/services/apis/authgatekeeper";
import $ from "jquery";
import "datatables.net-bs4";
import "pdfmake";
import "jszip";
import "datatables.net-buttons-bs4";
import "datatables.net-responsive-bs4";
import "datatables.net-fixedcolumns";
import "datatables.net-responsive/js/dataTables.responsive";
import "datatables.net-buttons/js/dataTables.buttons";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";

const Users = () => {
  //const [activeTab, setActiveTab] = useState("ACTIVITY");
  //const [t] = useTranslation();
  const dispatch = useDispatch();
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await authgatekeeper.loadUsers();
        dispatch(loadUsers(response));
        await sleep(1000);
        //setIsAppLoaded(true);
      } catch (error) {
        //throw error;
        //dispatch(logoutUser());
        await sleep(1000);
        // setIsAppLoaded(true);
      }
    };
    fetchUsers();
  }, [dispatch]);
  const usersList = useSelector((state: any) => state.auth.usersList);

  $("#example").DataTable({
    dom: '<"top wrapper"lfi>rtp<"clear"><"bottom"B>',
    // buttons: true,
    ordering: true,
    searching: true,
    paging: true,
    retrieve: true,
    processing: true,
    //pagingType: "full_numbers",
    data: usersList.users,
    lengthMenu: [
      [10, 25, 50, -1],
      [10, 25, 50, "All"],
    ],
    lengthChange: true,
    deferLoading: 57,
    responsive: {
      details: true,
    },
    columns: [
      { data: "id" },
      { data: "name" },
      { data: "mobile" },
      { data: "email" },
      { data: "dob" },
      { data: "pan_number" },
      { data: "aadhar_number" },
      { data: "role_text" },
      {
        data: "id",
        render: function (data, type, row, meta) {
          return `<a href='users/details/${data}'><i class='fa fa-eye text-info'></i></a> <a href='users/edit/${data}'><i class='fa fa-edit text-warning'></i></a> <a href='users/remove/${data}'><i class='fa fa-trash text-danger'></i></a>`;
        },
      },
    ],
  });
  // new $.fn.dataTable.(table, {
  //   buttons: ["copy", "excel", "pdf"],
  // });
  return (
    <div>
      <ContentHeader title="Users Page" />
      <section className="content">
        <div className="container-fluid">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">Users</h3>
              <div className="card-tools">
                <a
                  href="users/create"
                  className="btn btn-tool bg-info"
                  data-widget="collapse"
                  data-toggle="tooltip"
                  title="Collapse"
                >
                  <i className="fa fa-plus" /> Add User
                </a>
              </div>
            </div>
            <div className="card-body table-responsive">
              <table
                id="example"
                className="table table-hover table-bordered table-striped"
              >
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Name</th>
                    <th>Mobile</th>
                    <th>Email</th>
                    <th>Date Of Birth</th>
                    <th>Pan Number</th>
                    <th>Aadhar Number</th>
                    <th>Role</th>
                    <th>Is Online</th>
                  </tr>
                </thead>
              </table>
            </div>
            <div className="card-footer">Footer</div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Users;
