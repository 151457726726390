/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import { ContentHeader } from "@components";
//import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
//import { useNavigate } from "react-router-dom";
import { sleep } from "@app/utils/helpers";
import { loadUserDetail } from "@app/store/reducers/auth";
import { useParams } from "react-router-dom";
import authgatekeeper from "@app/services/apis/authgatekeeper";

const UserDetails = () => {
  //const [activeTab, setActiveTab] = useState("ACTIVITY");
  //const [t] = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    const fetchUserDetail = async () => {
      try {
        const response = await authgatekeeper.loadUserDetails("17");
        dispatch(loadUserDetail(response));
        await sleep(1000);
        //setIsAppLoaded(true);
      } catch (error) {
        //throw error;
        //dispatch(logoutUser());
        await sleep(1000);
        // setIsAppLoaded(true);
      }
    };
    fetchUserDetail();
  }, [dispatch, id]);

  return (
    <div>
      <ContentHeader title="Users Page" />
      <section className="content">
        <div className="container-fluid">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">Users</h3>
              <div className="card-tools">
                <button
                  type="button"
                  className="btn btn-tool"
                  data-widget="collapse"
                  data-toggle="tooltip"
                  title="Collapse"
                >
                  <i className="fa fa-minus" />
                </button>
                <button
                  type="button"
                  className="btn btn-tool"
                  data-widget="remove"
                  data-toggle="tooltip"
                  title="Remove"
                >
                  <i className="fa fa-times" />
                </button>
              </div>
            </div>
            <div className="card-body">
              Start creating your amazing application!
            </div>
            <div className="card-footer">Footer</div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default UserDetails;
