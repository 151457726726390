import { createSlice } from "@reduxjs/toolkit";

export interface AuthState {
  isLoggedIn: boolean;
  token: string | null;
  currentUser: any;
  userDetail: any;
  usersList: any;
}

const initialState: AuthState = {
  isLoggedIn: !!localStorage.getItem("token"),
  token: localStorage.getItem("token"),
  currentUser: {
    email: "mail@example.com",
    picture: null,
  },
  userDetail: {},
  usersList: {},
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginUser: (state, { payload }) => {
      localStorage.setItem("token", payload);
      state.isLoggedIn = true;
      state.token = payload;
    },
    logoutUser: (state) => {
      localStorage.removeItem("token");
      state.currentUser = {};
      state.userDetail = {};
      state.isLoggedIn = false;
      state.token = null;
    },
    loadUser: (state, { payload }) => {
      state.currentUser = payload;
    },
    loadUserDetail: (state, { payload }) => {
      state.userDetail = payload;
    },
    loadUsers: (state, { payload }) => {
      state.usersList = payload;
    },
  },
});

export const { loginUser, logoutUser, loadUser, loadUserDetail, loadUsers } =
  authSlice.actions;

export default authSlice.reducer;
